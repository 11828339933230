import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { Button, Divider, IconClose, Input } from 'takeat-design-system-ui-kit';
import mgmLogo from '../../assets/images/mgmLogo.png';
import celebration from '../../assets/images/celebration.svg';
import { StyledModal } from './styles';
import { toast } from 'react-toastify';
import { addHours, isAfter } from 'date-fns';
import api from '../../services/api';
import { useAuth } from '../../context/auth';

export const ModalMGM = () => {
  const theme = useTheme();
  const { waiter, userToken, setWaiter } = useAuth();
  const timeToShowAgain = JSON.parse(
    localStorage.getItem('@waitermanager:timeToShowAgain'),
  );

  const now = new Date();
  const [phoneState, setPhoneState] = useState({ value: '', error: '' });
  const [modalStep, setModalStep] = useState(0);
  const [modalOpen, setModalOpen] = useState(
    !waiter.phone &&
      (timeToShowAgain ? isAfter(now, new Date(timeToShowAgain)) : true),
  );
  const [loadingUpdatePhone, setLoadingUpdatePhone] = useState(false);

  const handleUpdatePhone = async () => {
    if (!phoneState.value) {
      setPhoneState(state => ({ ...state, error: 'Campo obrigatório' }));
      return;
    }
    if (phoneState.value.length < 15) {
      setPhoneState(state => ({ ...state, error: 'Telefone inválido' }));
      return;
    }
    setPhoneState(state => ({ ...state, error: '' }));
    setLoadingUpdatePhone(true);
    try {
      const { data } = await api.put(
        `/waiter/update`,
        {
          phone: phoneState.value,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      );
      setWaiter(state => ({ ...state, ...data }));
      setModalStep(2);
    } catch (e) {
      toast.error('Erro ao enviar o telefone. Tente novamente mais tarde.');
      console.error('Error handleUpdatePhone: ', e);
    }
    setLoadingUpdatePhone(false);
  };

  const updateTimeToShowAgain = () => {
    localStorage.setItem(
      '@waitermanager:timeToShowAgain',
      JSON.stringify(addHours(now, 5)),
    );
  };

  return (
    <StyledModal
      open={modalOpen}
      toggle={() => {
        setModalOpen(false);
        updateTimeToShowAgain();
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconClose
          fill={theme.colors.neutral.dark}
          onClick={() => {
            setModalOpen(false);
            updateTimeToShowAgain();
          }}
        />
      </div>
      {modalStep === 0 && (
        <>
          <img src={mgmLogo} alt="Indique e ganhe" />
          <h1>Ganhe até R$5.500 indicando a Takeat! 🚀</h1>
          <p>
            Você não leu errado!{' '}
            <b style={{ color: theme.colors.yellow.default }}>Ganhe R$400</b>{' '}
            por indicação e bônus incríveis!{' '}
            <b>Envie o número do seu telefone</b> e mandaremos todas as
            informações.
          </p>
          <Divider />
          <Button
            customColor={theme.colors.yellow.default}
            style={{ width: '100%' }}
            onClick={() => setModalStep(1)}
          >
            QUERO PARTICIPAR
          </Button>
        </>
      )}

      {modalStep === 1 && (
        <>
          <Input
            label="Número de telefone*"
            placeholder="DDD + Seu número"
            value={phoneState.value}
            onChange={e => {
              const newValue = e.target?.value;
              setPhoneState(state => ({ ...state, value: newValue }));
            }}
            error={phoneState.error}
            mask="(99) 99999-9999"
          />
          <Divider />
          <Button
            customColor={theme.colors.yellow.default}
            style={{ width: '100%' }}
            isLoading={loadingUpdatePhone}
            onClick={() => handleUpdatePhone()}
          >
            Enviar
          </Button>
        </>
      )}

      {modalStep === 2 && (
        <>
          <img src={celebration} alt="Imagem de comemoração" />
          <p style={{ fontSize: 18, fontWeight: 600 }}>
            Recebemos seu cadastro. Logo entraremos em contato!
          </p>
          <Divider />
          <Button
            variant="outlined"
            style={{ width: '100%' }}
            onClick={() => setModalOpen(false)}
          >
            Fechar
          </Button>
        </>
      )}
    </StyledModal>
  );
};
