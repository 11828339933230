import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import GlobalStyle from './styles/global';
import Routes from './routes';
import { UiKitTheme } from 'takeat-design-system-ui-kit';

function App() {
  return (
    <>
      <UiKitTheme>
        <GlobalStyle />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </UiKitTheme>
    </>
  );
}

export default App;
