import React, {
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
} from 'react';

import { FiHelpCircle, FiMenu } from 'react-icons/fi';

import {
  Container,
  ButtonAction,
  ButtonOption,
  OrdersPendingBadge,
} from './styles';
import { FaHourglassEnd, FaClipboardCheck, FaTable } from 'react-icons/fa';
import api from '../../services/api';
import { useAuth } from '../../context/auth';
import { useOrders } from '../../context/ordersContext';
import { FaPix } from 'react-icons/fa6';

function Footer() {
  const [ordersCount, setOrdersCount] = useState(0);
  // const [pixesCount, setPixesCount] = useState(0);
  const [path, setPath] = useState();
  const { restaurantHasPix } = useAuth();
  const { ordersPending, ordersAccepted } = useOrders();

  const getOrdersCount = useCallback(async () => {
    const response = await api.get('/waiter/orders');
    const orders = response.data.filter(order => !order.waiter_checked);

    setOrdersCount(orders.length);
  }, []);

  // const getPixesCount = useCallback(async () => {
  //   const response = await api.get('/waiter/pix');
  //   const pixes = response.data.filter(item => !item.checked);

  //   setPixesCount(pixes.length);
  // }, []);

  useEffect(() => {
    setPath(window.location.pathname);
  }, [setPath]);

  return (
    <Container>
      <ButtonAction>
        <ButtonOption
          to={'/restauranttables'}
          path={path}
          name="/restauranttables"
        >
          <FaTable
            size={22}
            color={path === '/restauranttables' ? '#fff' : '#ff2b3a'}
          />
          Mesas
        </ButtonOption>
        <ButtonOption to={'/orderspending'} path={path} name="/orderspending">
          <FaHourglassEnd
            size={22}
            color={path === '/orderspending' ? '#fff' : '#ff2b3a'}
          />
          <OrdersPendingBadge>{ordersPending.length}</OrdersPendingBadge>
          Pendentes
        </ButtonOption>

        <ButtonOption to={'/ordersclosed'} path={path} name="/ordersclosed">
          <FaClipboardCheck
            size={22}
            color={path === '/ordersclosed' ? '#fff' : '#ff2b3a'}
          />
          Entregues
        </ButtonOption>

        <ButtonOption to={'/waitertickets'} path={path} name="/waitertickets">
          <FiHelpCircle size={22} color="#ff2b3a" />
          Ajuda
        </ButtonOption>
        {restaurantHasPix.toString() === 'true' && (
          <ButtonOption to={'/paymentspix'} path={path} name="/paymentspix">
            <FaPix size={22} color="#ff2b3a" />
            {/* <OrdersPendingBadge>{pixesCount}</OrdersPendingBadge> */}
            Pgtos Pix
          </ButtonOption>
        )}
      </ButtonAction>
    </Container>
  );
}

export default Footer;
