import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../context/auth';

import OrderPendingItem from './OrderPendingItem';

import api from '../../services/api';

import Footer from '../../components/Footer';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  MenuItemHeader,
  HeaderAction,
  SelectArea,
} from './styles';
import { useCallback } from 'react';
import { useOrders } from '../../context/ordersContext';

const OrdersPending = props => {
  const {
    restaurantFantasyName,
    waiterLogout,
    setRestaurantHasPix,
    restaurantUseCommand,
  } = useAuth();
  const { ordersPending, getOrders } = useOrders();
  const history = useHistory();
  const footerRef = useRef(null);

  const [path, setPath] = useState('');
  const [orders, setOrders] = useState([]);

  function logOut() {
    waiterLogout();
    history.push('/');
  }

  async function getMenuFilteredWaiters() {
    const orders = ordersPending
      .filter(order => !order.basket?.bill.buyer && !order.waiter_checked)
      .sort((a, b) => Number(b.id) - Number(a.id));

    setOrders(orders);
  }

  async function getMenuFilteredClients() {
    const orders = ordersPending
      .filter(order => order.basket?.bill.buyer && !order.waiter_checked)
      .sort((a, b) => Number(b.id) - Number(a.id));

    setOrders(orders);
  }

  function setOrderOwner(e) {
    switch (e) {
      case 'waiter':
        getMenuFilteredWaiters();
        break;
      case 'client':
        getMenuFilteredClients();
        break;
      default:
        setOrders([]);
        getOrders();
        break;
    }
  }

  // const getRestaurantInfo = useCallback(async () => {
  //   const response = await api.get('/waiter/has-pix')

  //   setRestaurantHasPix(response.data.restaurant_has_pix);
  // }, []);

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  const selectOtions = [
    { value: 'waiter', label: 'Garçom' },
    { value: 'client', label: 'Cliente' },
    { value: 'both', label: 'Ambos' },
  ];

  const [cabecalhoHeight, setCabecalhoHeight] = useState(0);

  useEffect(() => {
    const cabecalhoElemento = document.getElementById('cabecalho');

    if (cabecalhoElemento) {
      const height = cabecalhoElemento.offsetHeight;
      setCabecalhoHeight(height);
    }
  }, []);

  return (
    <Container>
      <Content>
        <HeaderAction id="cabecalho">
          <header>
            <h1>{restaurantFantasyName}</h1>
            <button onClick={() => logOut()}>Sair</button>
          </header>
          <div style={{ paddingLeft: 10 }}>Itens pendentes</div>
          <button
            onClick={() => {
              document.location.reload();
            }}
          >
            Atualizar Pedidos
          </button>

          <SelectArea>
            <label>Ver pedidos de:</label>
            <Select
              placeholder="Garçom/ Cliente"
              options={selectOtions}
              onChange={e => {
                setOrderOwner(e.value);
              }}
            />
          </SelectArea>
        </HeaderAction>
        <MenuContent cabecalhoHeight={cabecalhoHeight}>
          <MenuItem>
            {orders &&
              orders.length > 0 &&
              orders.map(item => (
                <OrderPendingItem
                  item={item}
                  getOrders={getOrders}
                  key={item?.id}
                />
              ))}
            {orders.length < 1 &&
              ordersPending &&
              ordersPending.length > 0 &&
              ordersPending.map(item => (
                <OrderPendingItem
                  item={item}
                  getOrders={getOrders}
                  key={item?.id}
                />
              ))}
          </MenuItem>
        </MenuContent>

        <Footer />
      </Content>
    </Container>
  );
};

export default OrdersPending;
