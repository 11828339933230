import styled from 'styled-components';
import { Modal } from 'takeat-design-system-ui-kit';

export const StyledModal = styled(Modal)`
  height: fit-content;
  padding: 16px;

  h1 {
    color: ${({ theme }) => theme.colors.neutral.darker};
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: var(--Headline-Small-Tracking, 0px);
  }

  p {
    color: ${({ theme }) => theme.colors.neutral.dark};
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
  }

  img {
    width: 129px;
    margin: 0 auto;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xs};
  }
`;
